<template>
  <div class="bind-card">
    <nav-header v-if="isPage"></nav-header>
    <div class="bc_top">
      <p>{{ title }}</p>
    </div>
    <div class="bc_middle">
      <a-carousel effect="fade" :dots="false">
        <div class="bc_card">
          <div class="bc_cardInfo">
            <div class="bc_cardleft">
              <img src="../../assets/jinke.png" alt="" />
            </div>
            <div class="bc_cardright">
              <a-form-model ref="ruleForm" :model="form" :rules="rule">
                <a-form-model-item ref="userName" prop="userName">
                  <a-input placeholder="会员姓名" v-model="form.userName">
                  </a-input
                ></a-form-model-item>
                <a-form-model-item ref="cardNo" prop="cardNo"
                  ><a-input
                    placeholder="请输入兑换卡卡号"
                    v-model="form.cardNo"
                  />
                </a-form-model-item>
                <a-form-model-item ref="cardPwd" prop="cardPwd"
                  ><a-input-password
                    :maxLength="6"
                    placeholder="请输入6位数密码"
                    v-model="form.cardPwd"
                  /> </a-form-model-item
              ></a-form-model>
            </div>
          </div>
          <div class="bc_line" />
        </div>
      </a-carousel>
      <div class="bc_tips">
        <p>
          点击“确定绑定”按钮，代表您已经阅读并同意接受<span
            @click="visible = true"
            class="active-text"
            >金恪集团会员规章</span
          >和<span class="active-text" @click="visible = true"
            >健康卡使用的特别规定</span
          >
        </p>
      </div>
    </div>
    <div class="bc_footer">
      <a-button
        type="primary"
        :disabled="
          form.userName == '' || form.cardNo == '' || form.cardPwd == ''
        "
        @click="toBindCard"
        >确认绑定</a-button
      >
    </div>
    <a-drawer
      title=""
      :placement="'bottom'"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
      <div>金恪集团会员规章和健康卡使用的特别规定</div>
    </a-drawer>
  </div>
</template>
<script>
import { getUserInfo } from "@/service";
import NavHeader from "@/components/NavHeader";
export default {
  name: "BindCard",
  data() {
    return {
      title: "绑定金恪康养会员卡",
      reminder:
        "点击“确定绑定”按钮，代表您已经阅读并同意接受金恪集团会员规章和健康卡使用的特别规定",
      form: {
        userName: "",
        cardNo: "",
        cardPwd: "",
      },
      rule: {
        userName: [
          { required: true, message: "请输入会员名称", trigger: "blur" },
          { min: 1, max: 6, message: "长度在1到5个字符", trigger: "blur" },
        ],
        cardNo: [
          { required: true, message: "请输入兑换卡卡号", trigger: "blur" },
        ],
        cardPwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, message: "请输入6位数密码", trigger: "change" },
        ],
      },
      isPage: true,
      visible: false,
    };
  },
  created() {
    this.isPage = this.$route.name === "bindCard";
  },
  mounted() {
    const merchantId = this.$store.state.merchantId;
    getUserInfo(merchantId);
  },
  components: { NavHeader },
  methods: {
    onClose() {
      this.visible = false;
    },
    toBindCard() {
      //先对卡号进行验证
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    fresh() {
      if (this.$route.name === "bindCard") {
        this.$router.push({
          name: "VipViewer",
          params: {
            tab: "myCard",
          },
        });
      } else {
        this.$eventBus.$emit("refreshCard");
      }
    },
    //绑卡
    submit() {
      const bindParams = { ...this.form };
      const merchantId = this.$store.state.merchantId;
      const params = { ...bindParams, merchantId };
      this.$service
        .post("/mc/api/user/card/bind", params)
        .then((res) => {
          if (res.data.resultCode === 0) {
            this.$message.success("绑卡成功");
            this.$nextTick(() => {
              this.$router.back(-1);
            });
          } else {
            this.$message.warning(res.data.resultMsg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.bind-card {
  padding-top: 0.5rem;
}
.bc_top {
  height: 0.5rem;
  line-height: 0.5rem;
  padding-left: 0.14rem;
  box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.18);
  position: relative;
  p {
    position: absolute;
    font-size: 0.26rem;
    bottom: 0;
    padding: 0;
    margin: 0;
    font-weight: 600;
    color: #111111;
  }
}
.bc_middle {
  padding: 0.2rem 0.28rem 0;
  .ant-carousel {
    height: 2.4rem;
    box-shadow: 0 0.04rem 0.06rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.12rem;
    ::v-deep .slick-dots {
      li.slick-active {
        button {
          width: 0.06rem;
          background: #00a861;
        }
      }
      li {
        margin: 0 0.05rem;
        button {
          width: 0.06rem;
          height: 0.06rem;
          border-radius: 50%;
          background: #bfbfbf;
        }
      }
    }
    ::v-deep .slick-dots-bottom {
      bottom: -0.18rem;
    }
  }
  .bc_card {
    height: 2.4rem;
    ::v-deep {
      .ant-form-explain,
      .ant-form-extra {
        font-size: 10px;
        line-height: 14px;
        min-height: auto;
      }
    }
    .bc_cardInfo {
      height: 100%;
      display: flex !important;
      position: relative;
      .bc_cardleft {
        flex: 1;
        img {
          width: 60%;
          margin: auto;
          margin-top: 0.18rem;
        }
      }
      .bc_cardright {
        flex: 3;
        background: #f7f7f7;
        border-radius: 0 0.12rem 0.12rem 0;
        padding: 0.15rem;
        ::v-deep .ant-form {
          overflow: auto;
          height: 75%;
        }
        ::v-deep .ant-form-item {
          margin-bottom: 0;
          height: 50px !important;
        }
        ::v-deep .ant-input {
          background-color: unset;
          border: unset;
          border-radius: unset;
          border-bottom: 0.02rem solid #e1e1e1;
          box-shadow: none;
          // height: 5.4vh;
        }
        ::-webkit-input-placeholder {
          font-size: 0.14rem;
          font-weight: 500;
          color: #999999;
        }
      }
    }
    .bc_line {
      height: 17%;
      background-color: #c79d6a;
      position: relative;
      bottom: 0.5rem;
    }
  }
  .bc_tips {
    margin-top: 0.4rem;
    p {
      font-size: 0.12rem;
      font-weight: 400;
      color: #111111;
    }
  }
}
.bc_footer {
  padding: 0 0.14rem;
  float: right;
  position: absolute;
  right: 0;
  bottom: 0.44rem;
  width: 35%;
  ::v-deep .ant-btn {
    width: 100%;
    height: 7vh;
    box-shadow: 0 0 0.08rem 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    font-size: 0.16rem;
    font-weight: 500;
  }
  ::v-deep .ant-btn-primary[disabled] {
    background: #d4d4d4;
    color: #ffffff;
  }
}
</style>
